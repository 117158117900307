import axios from "axios";
import appConfig from "configs/app.config";
import { REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE } from "constants/api.constant";
import { ACCESS_TOKEN, HTTP_STATUS_CODES } from "constants/app.constant";

const unauthorizedCode = [401];

const BaseService = axios.create({
    timeout: 60000 * 5,
    baseURL: appConfig.baseURL,
});

BaseService.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN)?.trim();
        if (!!accessToken) {
            config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
        } else {
            // Don't return a React component, instead redirect using window.location
            window.location.href = "/signout";
            return Promise.reject(new Error("No access token found"));
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

BaseService.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (unauthorizedCode.includes(error.response?.status)) {
            localStorage.removeItem(ACCESS_TOKEN);
            sessionStorage.clear();

            window.location.href = "/signout"; // Redirect to login or handle logout
        }
        if (error?.code && error.code === HTTP_STATUS_CODES.ERR_NETWORK) {
            // Don't return a React component, instead redirect using window.location
            window.location.href = "/error-page";
            return Promise.reject(new Error("Network error"));
        }
        return Promise.reject(error);
    },
);

export default BaseService;
