import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    channelsCount: -1,
    totalChannelsCount: -1,
    selectedChannel: {},
    channels: [],
};

const channelsConfigureSlice = createSlice({
    name: "channelsConfigure",
    initialState,
    reducers: {
        setChannelsCount: (state, action) => {
            state.channelsCount = action.payload;
        },

        setTotalChannelsCount: (state, action) => {
            state.totalChannelsCount = action.payload;
        },
        setSelectedChannel: (state, action) => {
            state.selectedChannel = action.payload;
        },
        setChannels: (state, action) => {
            state.channels = action.payload;
        },
    },
});

export const { setChannelsCount, setTotalChannelsCount, setSelectedChannel, setChannels, setChannelUpdate } =
    channelsConfigureSlice.actions;

export default channelsConfigureSlice.reducer;
