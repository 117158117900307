import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "assets/png/logo-light-full.png";

export const GenerateContractPDF = (calculationResult, customerData, startDate, endDate, saveImmediately = false) => {
    if (!calculationResult) return null;

    const doc = new jsPDF();
    let yPos = 20;

    // Header
    // Replace TREPLY text with image
    const logoWidth = 50; // Adjust this value to change the width
    const logoHeight = 10; // Adjust this value to change the height
    doc.addImage(logo, "PNG", 20, yPos - 5, logoWidth, logoHeight);

    doc.setFontSize(10);
    doc.text(
        `Quote Expires: ${new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString()}`,
        150,
        yPos,
        null,
        null,
        "right",
    );

    // Title
    yPos += 20;
    doc.setFontSize(16);
    doc.setFont(undefined, "bold");
    doc.text("Treply Annual Service Agreement", 105, yPos, null, null, "center");
    doc.setFont(undefined, "normal");

    // Parties
    yPos += 15;
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.text("Parties:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    doc.setFontSize(10);
    const partiesText = `This Agreement is made between Treply.so ("Treply") and ${customerData.name} ("Customer"). Both parties agree to the terms and conditions below effective as of the Effective Date specified in this Agreement.`;
    const splitPartiesText = doc.splitTextToSize(partiesText, 170);
    doc.text(splitPartiesText, 20, yPos);

    yPos += 15;

    // Term and Renewal
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.text("Term and Renewal", 20, yPos);
    doc.setFont(undefined, "normal");
    yPos += 5;

    doc.setFontSize(10);

    doc.setFont(undefined, "normal");
    const termText = `The term of this Agreement begins on the Effective Date and continues for one (1) year ("Initial Term"). This is a mandatory annual contract commitment.`;
    const splitTermText = doc.splitTextToSize(termText, 160);
    doc.text(splitTermText, 20, yPos);

    yPos += 15;

    // Force a new page for Auto-Renewal
    // doc.addPage();
    // yPos += 5;

    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text("Auto-Renewal:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    const renewalText = `Upon expiration of the Initial Term, this Agreement will automatically renew for successive one-year terms ("Renewal Term(s)") unless either party gives written notice of non-renewal at least 30 days before the end of the then-current term. During any Renewal Term, Treply may adjust the pricing or fees by providing at least 30 days' prior written notice of the change. If the Customer does not accept a proposed fee increase, the Customer may elect to give notice of non-renewal to prevent automatic renewal of the Agreement at the new rates.`;
    const splitRenewalText = doc.splitTextToSize(renewalText, 160);
    doc.text(splitRenewalText, 20, yPos);

    yPos += 30;

    // // Check if we need a new page
    // if (yPos > 200) {
    //     doc.addPage();
    //     yPos = 20;
    // }

    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text("Effective Date and Binding Agreement:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    const effectiveText = `This Agreement becomes binding on the Effective Date once signed by authorized representatives of both parties. The pricing and terms herein take effect from that date.`;
    const splitEffectiveText = doc.splitTextToSize(effectiveText, 160);
    doc.text(splitEffectiveText, 20, yPos);

    yPos += 15;

    // Fees and Pricing
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.text("Fees and Pricing", 20, yPos);
    doc.setFont(undefined, "normal");
    yPos += 5;

    doc.setFontSize(10);
    const feesText = `Treply will provide its text messaging services to Customer under the following fee structure (all amounts in USD):`;
    const splitFeesText = doc.splitTextToSize(feesText, 170);
    doc.text(splitFeesText, 20, yPos);

    yPos += splitFeesText.length * 5 + 5;

    // Message Sending Fees
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text("Message Sending Fees:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    const messageFeeText = `Customer shall pay $${calculationResult.costPerSMS.toFixed(4)} per message segment sent, plus any applicable carrier fees. A "message segment" is up to 160 characters for standard SMS (or 70 characters for Unicode messages); messages exceeding these lengths will be split and billed as multiple segments. MMS messages are billed at 3x the SMS price. This per-message charge applies to each outbound SMS or MMS message sent through Treply's platform. (Inbound messages received are not charged by Treply under this Agreement, except that any carrier-imposed fees on inbound messages will be passed through as described below.)`;
    const splitMessageFeeText = doc.splitTextToSize(messageFeeText, 160);
    doc.text(splitMessageFeeText, 20, yPos);

    yPos += 30;

    // Carrier Surcharges
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text("Carrier Surcharges:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    const carrierText = `In addition to the $${calculationResult.costPerSMS.toFixed(4)} message fee, Customer is responsible for all carrier delivery fees or surcharges imposed by mobile carriers for SMS/MMS messaging. Treply will pass these carrier fees through to the Customer at cost. Carrier fees are subject to change at any time as determined by the cellular carriers, and such Carrier Delivery Fees will be billed to the Customer. (For reference, current carrier fees per message segment may range from approx. $0.0025 to $0.01 depending on the carrier, but are subject to change.)`;
    const splitCarrierText = doc.splitTextToSize(carrierText, 160);
    doc.text(splitCarrierText, 20, yPos);

    yPos += 30;

    // Phone Number Rental
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text("Phone Number Rental:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    const phoneText = `Customer shall pay a $2.75 per month rental fee for each dedicated phone number (e.g. long code phone number) provided by Treply for Customer's use. This fee is charged monthly per number. Treply will provision at least one phone number for Customer's texting program; additional numbers may be obtained upon mutual agreement and will incur the same monthly fee per number.`;
    const splitPhoneText = doc.splitTextToSize(phoneText, 160);
    doc.text(splitPhoneText, 20, yPos);

    yPos += 15;

    // Check if we need a new page
    if (yPos > 230) {
        doc.addPage();
        yPos = 20;
    }

    // Taxes
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text("Taxes:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    const taxesText = `All fees described above are exclusive of any applicable taxes. Customer is responsible for any sales, use, value-added, or similar taxes or government charges on the services, if applicable (excluding taxes on Treply's income). Treply will itemize any such taxes on the invoice when required by law.`;
    const splitTaxesText = doc.splitTextToSize(taxesText, 160);
    doc.text(splitTaxesText, 20, yPos);

    yPos += 20;

    // Billing and Payment Terms
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.text("Billing and Payment Terms", 20, yPos);
    doc.setFont(undefined, "normal");
    yPos += 10;

    // Invoicing
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text("Invoicing:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    const invoicingText = `Treply will invoice Customer on a monthly basis for the services. Unless otherwise agreed, billing will occur at the end of each monthly period, covering: (a) the Message Sending Fees for all messages sent during that month, (b) the Phone Number rental fee for that month, and (c) any Carrier Surcharges incurred during that month. Treply may send invoices electronically to the Customer's designated billing contact.`;
    const splitInvoicingText = doc.splitTextToSize(invoicingText, 160);
    doc.text(splitInvoicingText, 20, yPos);

    yPos += 25;

    // Payment Due Date
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text("Payment Due Date:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    const paymentDueText = `Payment of each invoice is due within 30 days from the invoice date (NET 30 terms), unless otherwise specified in the invoice. Payment shall be made in USD via acceptable methods as listed below.`;
    const splitPaymentDueText = doc.splitTextToSize(paymentDueText, 160);
    doc.text(splitPaymentDueText, 20, yPos);

    yPos += 15;

    // Payment Methods
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text("Payment Methods:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    const paymentMethodsText = `Customer can make payments via wire transfer, ACH/EFT, or credit card. If Customer elects to pay by credit card, an additional processing fee of up to 3% of the charged amount may apply to cover credit card transaction fees. Treply will include any such processing fee on the invoice if applicable. There are no additional fees for payments via ACH or wire transfer.`;
    const splitPaymentMethodsText = doc.splitTextToSize(paymentMethodsText, 160);
    doc.text(splitPaymentMethodsText, 20, yPos);

    yPos += 25;

    // Early Termination and Cancellation
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.text("Early Termination and Cancellation", 20, yPos);
    doc.setFont(undefined, "normal");
    yPos += 5;

    doc.setFontSize(10);
    const terminationText = `This is a fixed-term yearly contract. Customer is expected to fulfill the entire Initial Term. Early termination by Customer without cause is permitted only under the conditions below, and may incur a termination fee. The intent of the termination fee is to discourage early cancellation and to compensate Treply for losses, while remaining reasonable and enforceable.`;
    const splitTerminationText = doc.splitTextToSize(terminationText, 170);
    doc.text(splitTerminationText, 20, yPos);

    yPos += 25;

    // Termination for Cause
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text("Termination for Cause:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    const terminationCauseText = `Either party may terminate this Agreement before the end of the term if the other party materially breaches the Agreement and fails to cure such breach within 30 days after receiving written notice of the breach. For example, if Treply fails to provide the contracted services or if Customer fails to comply with the usage or payment terms, and such failure is not corrected within 30 days of notice, the non-breaching party may terminate the Agreement with immediate effect upon written notice after the cure period. In the event of termination due to Treply's uncured breach, Customer will not be responsible for any termination fee or further payments beyond the termination date (Customer will only pay for services actually provided up to termination). In the event of termination due to Customer's breach (such as non-payment or unlawful use of the service), Treply may terminate the Agreement and the Early Termination Fee described below shall become immediately due and payable by Customer as liquidated damages.`;
    const splitTerminationCauseText = doc.splitTextToSize(terminationCauseText, 160);
    doc.text(splitTerminationCauseText, 20, yPos);

    yPos += 50;

    // Termination for Convenience
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text("Termination for Convenience by Customer:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    const terminationConvenienceText = `Customer may elect to terminate this Agreement for convenience (i.e. without cause) before the end of the contract term. To do so, Customer must provide Treply with at least 30 days' prior written notice of the intent to terminate early. Early termination will be effective 30 days after Treply's receipt of such notice (or a later date specified in the notice, if requested by Customer). As a condition of early termination for convenience, Customer must pay an Early Termination Fee to Treply, as described below. By agreeing to this provision, Customer acknowledges that the Early Termination Fee is a fair and reasonable estimate of the losses and damages Treply would suffer from Customer's early cancellation.`;
    const splitTerminationConvenienceText = doc.splitTextToSize(terminationConvenienceText, 160);
    doc.text(splitTerminationConvenienceText, 20, yPos);

    yPos += 20;

    // Check if we need a new page
    if (yPos > 230) {
        doc.addPage();
        yPos = 20;
    }

    // Early Termination Fee
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text("Early Termination Fee:", 20, yPos);
    yPos += 5;
    doc.setFont(undefined, "normal");
    const terminationFeeText = `If Customer terminates the Agreement early without cause or if Treply terminates the Agreement early due to Customer's material breach, Customer shall pay Treply an Early Termination Fee. The Early Termination Fee shall be equal to 50% of the total fees for the remaining portion of the contract term, calculated as follows:
- Treply will determine the average monthly Message Sending Fees and Phone Number fees incurred by Customer prior to termination (averaged over the months elapsed in the term up to termination). If less than three months have elapsed, a reasonable projected monthly usage may be used instead of an average.
- The average monthly fees determined above will be multiplied by the number of months (or partial months) remaining in the Initial Term (or then-current Renewal Term) as of the effective termination date.
- The Early Termination Fee is 50% of that amount. For example, if Customer has 6 months remaining and their average monthly fees have been $1,000, the Early Termination Fee would equal 50% × ($1,000 × 6) = $3,000.`;
    const splitTerminationFeeText = doc.splitTextToSize(terminationFeeText, 160);
    doc.text(splitTerminationFeeText, 20, yPos);

    yPos += splitTerminationFeeText.length * 5 + 5;

    // Check if we need a new page
    if (yPos > 250) {
        doc.addPage();
        yPos = 20;
    }

    // Fee Summary
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.text("Fee Summary", 20, yPos);
    doc.setFont(undefined, "normal");
    yPos += 10;

    // Create a table for fee summary
    doc.autoTable({
        startY: yPos,
        head: [["Description", "Value"]],
        body: [
            ["Cost per SMS", `$${calculationResult.costPerSMS.toFixed(4)}`],
            ["Cost per MMS", `$${(calculationResult.costPerSMS * 3).toFixed(4)}`],
            ["Carrier Fees", "$0.003 per SMS"],
            ["Early Termination Fee", "50% of remaining contract value"],
        ],
        theme: "striped",
        headStyles: { fillColor: [66, 139, 202] },
    });

    yPos = doc.lastAutoTable.finalY + 15;

    // Check if we need a new page
    if (yPos > 250) {
        doc.addPage();
        yPos = 20;
    }

    // Signatures
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.text("Acknowledged and Agreed by the Parties:", 20, yPos);
    doc.setFont(undefined, "normal");
    yPos += 15;

    // Customer signature
    doc.text("Customer:", 20, yPos);
    yPos += 10;
    doc.text("By: _______________________________", 20, yPos);
    yPos += 10;
    doc.text("Name: _____________________________", 20, yPos);
    yPos += 10;
    doc.text("Title/Company: _____________________", 20, yPos);
    yPos += 10;
    doc.text("Date: _____________________________", 20, yPos);

    yPos += 20;

    // Treply signature
    doc.text("Treply:", 20, yPos);
    yPos += 10;
    doc.text("By: _______________________________", 20, yPos);
    yPos += 10;
    doc.text("Name: _____________________________", 20, yPos);
    yPos += 10;
    doc.text("Title: _____________________________", 20, yPos);
    yPos += 10;
    doc.text("Date: _____________________________", 20, yPos);

    if (saveImmediately) {
        doc.save("treply-annual-contract.pdf");
        return null;
    } else {
        return doc;
    }
};

// Function to save the PDF document
export const savePDF = (doc) => {
    if (!doc) return;
    doc.save("treply-annual-contract.pdf");
};
