import React, { lazy, memo, Suspense, useEffect, useMemo, useState } from "react";
import { Loading } from "components/shared";
import { useDispatch, useSelector } from "react-redux";
import {
    LAYOUT_TYPE_BLANK,
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
} from "constants/theme.constant";
import { CHANNEL_NOT_VERIFIED, CHANNEL_VERIFICATION_FAILED } from "../../constants/channels.constant";
import useDirection from "utils/hooks/useDirection";
import useLocale from "utils/hooks/useLocale";
import { useAuth0 } from "@auth0/auth0-react";
import UserSlice, { setUser } from "store/auth/userSlice";
import { apiUserInfo } from "services/AuthService";
import { onSignInSuccess } from "store/auth/sessionSlice";
import { useNavigate } from "react-router-dom";
import { injectReducer } from "../../store";
import { ACCESS_TOKEN } from "constants/app.constant";
import { setGlobalAlert } from "components/ui/GlobalAlert/store";
import useAccessCheck from "utils/hooks/useAccessCheck";
import { resetTheme } from "store/theme/themeSlice";
import channelsConfigureReducer from "views/account/ChannelsConfigure/store/ChannelSlice";
import VerificationFailedBanner from "components/shared/VerificationFailedBanner";

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import("./ClassicLayout")),
    [LAYOUT_TYPE_MODERN]: lazy(() => import("./ModernLayout")),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import("./StackedSideLayout")),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import("./SimpleLayout")),
    [LAYOUT_TYPE_DECKED]: lazy(() => import("./DeckedLayout")),
    [LAYOUT_TYPE_BLANK]: lazy(() => import("./BlankLayout")),
};

const CURRENT_VERSION = "1.0.2"; // Ensure this matches the version in themeSlice

const Layout = () => {
    injectReducer("user", UserSlice);
    injectReducer("channelsConfigure", channelsConfigureReducer);

    useAccessCheck("messages");
    const dispatch = useDispatch();
    const layoutType = useSelector((state) => state.theme.layout.type);
    const channels = useSelector((state) => state.channelsConfigure?.channels || []);
    const { isAuthenticated, user, logout, getAccessTokenSilently } = useAuth0();
    const [dataLoaded, setDataLoaded] = useState(false);
    const navigate = useNavigate();

    const hasVerificationFailed = useMemo(() => {
        const failed = channels.some((channel) =>
            [CHANNEL_NOT_VERIFIED, CHANNEL_VERIFICATION_FAILED].includes(
                channel?.verificationResponse?.status || channel?.status,
            ),
        );
        return failed;
    }, [channels]);

    useEffect(() => {
        // Check and reset theme if version has changed
        const storedVersion = localStorage.getItem("app_version");
        if (storedVersion !== CURRENT_VERSION) {
            dispatch(resetTheme());
            localStorage.setItem("app_version", CURRENT_VERSION);
        }

        async function setData() {
            if (isAuthenticated) {
                localStorage.setItem("user_id", user.sub);
                try {
                    const token = await getAccessTokenSilently();
                    if (token) {
                        dispatch(onSignInSuccess(token));
                        localStorage.setItem(ACCESS_TOKEN, token);
                        try {
                            const userInfo = await apiUserInfo({ email: user.email });
                            dispatch(setUser(userInfo));
                            if (userInfo?.organizationDetails?.active === false) {
                                dispatch(
                                    setGlobalAlert({
                                        alertType: "danger",
                                        alert: `You are currently not subscribed to any plan.`,
                                        closable: false,
                                    }),
                                );
                            }
                        } catch (error) {
                            handleApiError(error);
                        }
                    } else {
                        navigate("/error-page");
                    }
                } catch (error) {
                    navigate("/error-page");
                }
            }
            setDataLoaded(true);
        }

        function handleApiError(error) {
            const errorMessagesToCheck = ["jwt expired", "ERR_NETWORK", "Missing Refresh Token"];
            const errorMessage = error?.code ? error?.code : error?.response?.data?.message;
            // console.log(error);

            if (errorMessagesToCheck.includes(errorMessage)) {
                localStorage.clear();
                sessionStorage.clear();
                logout({
                    returnTo: window.location.origin,
                });
            } else {
                console.log(error);
                navigate("/error-page");
            }
        }

        setData();
    }, [dispatch, isAuthenticated, user, logout, getAccessTokenSilently, navigate]);

    useDirection();
    useLocale();

    const AppLayout = useMemo(() => {
        if (isAuthenticated) {
            return layouts[layoutType];
        }
        return lazy(() => import("./AuthLayout"));
    }, [layoutType, isAuthenticated]);

    return (
        <Suspense
            className="font-opensans"
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            {dataLoaded && (
                <>
                    {hasVerificationFailed && <VerificationFailedBanner />}
                    <AppLayout />
                </>
            )}
        </Suspense>
    );
};

export default memo(Layout);
