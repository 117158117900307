import React, { useEffect, useState } from "react";
import { Button, Dialog } from "components/ui";
import { savePDF } from "utils/GenerateContractPDF";

const PDFPreviewModal = ({ isOpen, onClose, pdfDoc }) => {
    const [dataUrl, setDataUrl] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (pdfDoc && isOpen) {
            // Convert the PDF to a data URL for preview
            setTotalPages(pdfDoc.getNumberOfPages());
            const pdfDataUrl = pdfDoc.output("datauristring");
            setDataUrl(pdfDataUrl);
        }
    }, [pdfDoc, isOpen]);

    const handleSave = () => {
        if (pdfDoc) {
            savePDF(pdfDoc);
            onClose();
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            width={800}
            closable={true}
            title="Contract Preview"
            footer={
                <div className="text-right">
                    <Button className="mr-2" variant="plain" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button variant="solid" onClick={handleSave}>
                        Save PDF
                    </Button>
                </div>
            }
        >
            <div className="p-4">
                <div className="flex justify-between items-center mb-4">
                    <div>
                        <span className="font-semibold">
                            Page {currentPage} of {totalPages}
                        </span>
                    </div>
                    <div>
                        <Button
                            className="mr-2"
                            variant="plain"
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </Button>
                        <Button variant="plain" onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Next
                        </Button>
                    </div>
                </div>
                <div className="border rounded overflow-auto" style={{ height: "70vh" }}>
                    {dataUrl ? (
                        <iframe
                            src={`${dataUrl}#page=${currentPage}`}
                            title="PDF Preview"
                            width="100%"
                            height="100%"
                            style={{ border: "none" }}
                        />
                    ) : (
                        <div className="flex justify-center items-center h-full">
                            <span>Loading PDF preview...</span>
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

export default PDFPreviewModal;
