import React from "react";

const VerificationFailedBanner = () => {
    return (
        <div className="bg-red-500 text-white text-center py-2">
            <strong>Verification Failed:</strong> One or more channels have failed verification. Please review and fix
            the issues.
        </div>
    );
};

export default VerificationFailedBanner;
